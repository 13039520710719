import * as React from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { Helmet } from 'react-helmet-async';
import { graphql, useStaticQuery } from 'gatsby';

const useSiteMetaData = () =>
  useStaticQuery<GatsbyTypes.SiteMetaDataQuery>(graphql`
    query SiteMetaData {
      site {
        siteMetadata {
          siteUrl
          title
          description
        }
      }
    }
  `);

interface Props {
  language: string;
  currentCategory?: string;
  description?: string;
  pathname?: string;
}

const SEO: React.FC<Props> = ({ language, currentCategory, description, pathname }) => {
  const data = useSiteMetaData();
  return (
    <>
      <GatsbySeo
        titleTemplate={currentCategory ? `${data.site?.siteMetadata?.title} | %s` : data.site?.siteMetadata?.title}
        title={currentCategory || data.site?.siteMetadata?.title}
        description={description || data.site?.siteMetadata?.description}
        openGraph={{
          type: currentCategory ? 'article' : 'website',
          images: [
            {
              url: (data.site?.siteMetadata?.siteUrl || '') + '/og-image.png',
              width: 1200,
              height: 630,
              alt: data.site?.siteMetadata?.title,
            },
          ],
          site_name: data.site?.siteMetadata?.title,
          url: (data.site?.siteMetadata?.siteUrl || '') + (pathname || ''),
        }}
        language={language}
      />
      <Helmet>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, maximum-scale=5.0, minimum-scale=1'
        />
      </Helmet>
    </>
  );
};

export default SEO;
